@import "../../scss/fonts";
@import "../../scss/mixins";
@import "../../scss/variables";

.response-table {
  &.table > tbody > tr.response-table__user > td {
    background: #5d5d5c;
    color: white;

    &:hover {
      background: #5d5d5c;
      color: white;
    }
  }
}