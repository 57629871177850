@import "scss/_fonts.scss";
@import "scss/_mixins.scss";

.repair-info {
  &__list {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  }
  &__list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 15px;
    background-color: white;
    border-bottom: 1px solid #dddddd;
    @include regular();
    font-size: 14px;
    &_comment {
      display: block;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}