.tab-panel {
  position: relative;
  padding-top: 20px;
  &__close {
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: calc(-34px / 2);
    margin-top: calc(-34px / 2);
  }
}