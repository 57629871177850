.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  @include regular();
  line-height: inherit;
  color: #555555;
  background-color: white;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  &[disabled],
  &[readonly] {
    cursor: not-allowed;
    background-color: #eeeeee;
    opacity: 1
  }
}

textarea.form-control {
  height: auto;
  resize: none;
}