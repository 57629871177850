@import 'scss/_fonts.scss';
@import 'scss/_mixins.scss';
@import 'scss/_variables.scss';

.modal {
  width: 600px;
  margin: 30px auto;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  background-clip: padding-box;
  position: relative;
  &__close-button {
    appearance: none;
    border: 0;
    border-radius: 0;
    background: none;
    box-shadow: none;
    padding: 15px;
    margin: 0;
    cursor: pointer;
    color: rgba(0,0,0,.2);
    @include bold();
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    &:hover, &:focus {
      color: rgba(0,0,0,.5);
    }
  }
  &__section {
    margin-bottom: 15px;
  }
  &__section-title {
    margin-bottom: 7px;
    @include bold();
    font-size: 14px;
  }
  &__section-text {
    @include regular();
    font-size: 14px;
  }
  &__task-status {
    text-align: center;
    padding: 5px;
    background-color: $bgSuccess;
    @include regular();
    font-size: 14px;
  }
}