@import 'scss/_fonts.scss';
@import 'scss/_mixins.scss';
@import 'scss/_variables.scss';

.modal-body {
  padding: 15px;
  &__title {
    font-size: 18px;
    @include regular();
    margin-bottom: 40px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__col {
    width: calc(100% / 2);
  }
  &__noty {
    padding: 3px 5px;
    background-color: $bgDanger;
  }
}