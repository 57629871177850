.bottom-navigation-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: #222222;
  background-image: linear-gradient(to bottom, #3c3c3c 0%, #222222 100%);
  background-repeat: repeat-x;
  border: 1px solid #080808;
  padding: 8px 0;
  &_loaded {
    padding: 0;
  }
}