@mixin regular() {
  font-family: 'PT Sans Caption', sans-serif;
  font-weight: normal;
};

@mixin bold() {
  font-family: 'PT Sans Caption', sans-serif;
  font-weight: bold;
};

@mixin reset-button-styles {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
}

// scss-docs-start border-radius-mixins
@mixin border-radius($radius: $border-radius, $fallback-border-radius: false) {
  border-radius: $radius;
}

@mixin border-top-radius($radius: $border-radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius: $border-radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin despatch-object-gradient-2colors($color: $despatch-active-color, $color2: $despatch-active-color) {
  background: $color;
  border-color: darken($color, 20%);
  background-image:
    linear-gradient(
        -45deg,
        $color2 25%,
        transparent 25%,
        transparent 50%,
        $color2 50%,
        $color2 75%,
        transparent 75%,
        transparent
    );
  background-size: 40px 40px;
  background-color: $color;
}