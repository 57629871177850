@import 'scss/_fonts.scss';
@import 'scss/_mixins.scss';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__content {
    padding-top: 72px;
    padding-bottom: 64px;
    flex-grow: 100;
  }
}