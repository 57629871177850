@import 'scss/_fonts.scss';
@import 'scss/_mixins.scss';
@import 'scss/_variables.scss';

.warning {
  padding: 10px;
  @include regular();
  font-size: 14px;
  background-color: $bgDanger;
  margin-bottom: 10px;
}