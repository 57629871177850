.table-report {
  &__orderman-tr {}
  &__orderman-td {
    background: #19aff0;
    color: black;
  }
  &__ks-tr {}
  &__ks-td {
    background: #ceffff;
    color: black;
  }
  &__repair-name-tr {}
  &__repair-name-td {
    background: #fcf60e;
    color: black;
  }
  &__work-item {
    font-size: 10px;
    width: 200px;
  }

  td.table-report__master {
    font-size: 10px;
    vertical-align: middle;
  }

  &__datestart {
    font-size: 10px;
  }
  &__dateend {
    font-size: 10px;
  }
}