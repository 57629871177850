.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  &__btn-to-left {
    margin-right: auto;
  }

  .btn + .btn {
    margin-left: 10px;
  }
}