@import "scss/_fonts.scss";
@import "scss/_mixins.scss";

.rejected-prolong-result {
  &__text {
    @include regular();
    font-size: 14px;
    margin-bottom: 10px;
  }
  &__reject {
    margin-top: 20px;
  }
  &__title {
    @include regular();
    font-size: 24px;
    margin-bottom: 10px;
  }
}