.panel {
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &_default {
    border-color: #dddddd;
  }
  &_primary {
    border-color: $primary;
  }
  &_success {
    border-color: #d6e9c6;
  }
  &_info {
    border-color: #bce8f1;
  }
  &_warning {
    border-color: #faebcc;
  }
  &_danger {
    border-color: #ebccd1;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    font-size: 14px;
    @include regular();

    .panel_default & {
      color: #333333;
      background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
      background-repeat: repeat-x;
      background-color: #f5f5f5;
      border-color: #dddddd;
    }

    .panel_primary & {
      color: white;
      background-image: linear-gradient(to bottom, $primary 0%, #357ebd 100%);
      background-repeat: repeat-x;
      background-color: $primary;
      border-color: $primary;
    }

    .panel_success & {
      color: #3c763d;
      background-image: linear-gradient(to bottom, $bgSuccess 0%, #d0e9c6 100%);
      background-repeat: repeat-x;
      background-color: #dff0d8;
      border-color: #d6e9c6;
    }

    .panel_info & {
      color: #31708f;
      background-image: linear-gradient(to bottom, $bgInfo 0%, #c4e3f3 100%);
      background-repeat: repeat-x;
      background-color: #d9edf7;
      border-color: #bce8f1;
    }

    .panel_warning & {
      color: #8a6d3b;
      background-image: linear-gradient(to bottom, $bgWarning 0%, #faf2cc 100%);
      background-repeat: repeat-x;
      background-color: #fcf8e3;
      border-color: #faebcc;
    }

    .panel_danger & {
      color: #a94442;
      background-image: linear-gradient(to bottom, $bgDanger 0%, #ebcccc 100%);
      background-repeat: repeat-x;
      background-color: #f2dede;
      border-color: #ebccd1;
    }
  }
  &__to-right {
    margin-left: auto;
  }
  &__body {
    padding: 15px;
    font-size: 14px;
    @include regular();
  }
}

.panel>.table {
  margin-bottom: 0
}

.panel>.table-bordered {
  border: 0
}

.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child {
  border-left: 0;
}

.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child {
  border-right: 0;
}

.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-bordered>tbody>tr:first-child>th {
  border-bottom: 0;
}

.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>th {
  border-bottom: 0;
}