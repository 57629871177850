@import 'scss/_variables.scss';
@import 'scss/_mixins.scss';

@mixin gpa-object-gradient($color: $gpa-active-color) {
  background: $color;
  border-color: darken($color, 20%);
  background-image:
    linear-gradient(
        45deg,
        darken($color, 10%) 25%,
        transparent 25%,
        transparent 50%,
        darken($color, 10%) 50%,
        darken($color, 10%) 75%,
        transparent 75%,
        transparent
    );
  background-size: 40px 40px;
  background-color: $color;
}

@mixin gpa-object-gradient-2colors($color: $gpa-active-color, $color2: $gpa-active-color) {
  background: $color;
  border-color: darken($color, 20%);
  background-image:
    linear-gradient(
        -45deg,
        $color2 25%,
        transparent 25%,
        transparent 50%,
        $color2 50%,
        $color2 75%,
        transparent 75%,
        transparent
    );
  background-size: 40px 40px;
  background-color: $color;
}

@mixin gpa-object-gradient-2parts($color: $gpa-active-color, $color2: $gpa-active-color) {
  border-color: darken($color, 20%);
  background-image:
    linear-gradient(
        -30deg,
        $color2 0,
        $color2 50%,
        $color 50%,
        $color 100%
    );
  background-color: $color;
}

.gpa-object {
  background: $gpa-active-color;
  border: 1px solid darken($gpa-active-color, 20%);
  display: block;
  color: black;
  text-decoration: none;
  margin-bottom: 3px;
  text-align: left;
  position: relative;
  overflow: hidden;
  padding: 8px;
  font-size: 14px;
  &:hover {
    text-decoration: none;
    background: darken($gpa-active-color, 20%);
  }
  &:last-child {
    margin-bottom: 0;
  }

  &_open {
    background: $gpa-open-color;
    border-color: darken($gpa-open-color, 20%);
    padding-right: 30px;
    &:hover {
      background: darken($gpa-open-color, 20%);
    }
  }
  &_canview {
    @include gpa-object-gradient($gpa-active-color);
  }
  &_canview_open {
    @include gpa-object-gradient($gpa-open-color);
  }
  &_finished {
    background: $gpa-finished-color;
    border-color: darken($gpa-finished-color, 20%);
    &:hover {
      background: darken($gpa-finished-color, 20%);
    }
  }
  &_canview_finished {
    @include gpa-object-gradient($gpa-finished-color);
    &:hover {
      background: darken($gpa-finished-color, 20%);
    }
  }
  &_avar {
    background: $gpa-avar-color;
    border-color: darken($gpa-avar-color, 20%);
    &:hover {
      background: darken($gpa-avar-color, 20%);
    }
  }
  &_open_avar {
    @include gpa-object-gradient-2colors($gpa-avar-color, $gpa-open-color);
    &:hover {
      background: darken($gpa-avar-color, 20%);
    }
  }
  &_finished_avar {
    @include gpa-object-gradient-2colors($gpa-avar-color, $gpa-finished-color);
    &:hover {
      background: darken($gpa-avar-color, 20%);
    }
  }
  &_garant {
    background: $gpa-garant-color;
    border-color: darken($gpa-garant-color, 20%);
    &:hover {
      background: darken($gpa-garant-color, 20%);
    }
  }
  &_open_garant {
    @include gpa-object-gradient-2colors($gpa-garant-color, $gpa-open-color);
    &:hover {
      background: darken($gpa-garant-color, 20%);
    }
  }
  &_pseudo-open_other {
    @include gpa-object-gradient-2parts($gpa-open-color, $gpa-finished-color);
    &:hover {
      @include gpa-object-gradient-2parts(darken($gpa-open-color, 10%), darken($gpa-finished-color, 10%));
    }
  }
  &_finished_garant {
    @include gpa-object-gradient-2colors($gpa-garant-color, $gpa-finished-color);
    &:hover {
      background: darken($gpa-garant-color, 20%);
    }
  }
  &_closed {
    background: $gpa-closed-color;
    border-color: darken($gpa-closed-color, 20%);
    &:hover {
      background: darken($gpa-closed-color, 20%);
    }
  }

  &__name {
    @include bold();
    font-size: 10px;
    margin-bottom: 4px;
    color: black;
  }
  &__number {
    @include regular();
    font-size: 10px;
    margin-bottom: 4px;
    color: black;
  }
  &__dates {
    @include regular();
    font-size: 9px;
    color: black;
  }
  &__type {
    @include bold();
    font-size: 10px;
    color: black;
  }
  &__indicator {
    position: absolute;
    right: 5px;
    &_accordance {
      top: 2px;
    }
    &_no-report {
      top: 20px;
    }
    &_overdue {
      top: 38px;
    }
    &_problem {
      top: 56px;
    }
    &_comment {
      bottom: -15px;
      right: -10px;
      width: 25px;
      height: 35px;
      background: #4059f2;
      transform: rotate(45deg);
    }
  }
}
