.repair {
  &__header {
    margin-bottom: 70px;
  }
  &__heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__content {}
}