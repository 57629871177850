@import "scss/variables";
@import "scss/reset";
@import "scss/fonts";
@import "scss/_mixins.scss";
@import "scss/_list-groups.scss";
@import "scss/utilites.scss";
@import "scss/blocks/container";
@import "scss/blocks/icons";
@import "scss/blocks/typography";
@import "scss/blocks/btn";
@import "scss/blocks/badge";
@import "scss/blocks/label";
@import "scss/blocks/well";
@import "scss/blocks/table";
@import "scss/blocks/panel";
@import "scss/blocks/form-control";
@import "scss/blocks/upload-docs";

body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include regular();
  font-size: 14px;
  line-height: 1.42857143;
  color: $textColor;
}
