@import "scss/_fonts.scss";
@import "scss/_mixins.scss";

.repair-comment {
  &__head {
    margin-bottom: 15px;
    @include bold();
    font-size: 14px;
  }
  &__body {
    @include regular();
    font-size: 14px;
  }
  &__footer {
    text-align: right;
    padding: 0 15px 15px;
  }
}