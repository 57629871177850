.drivers-page {
  &__title {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__tools {
    margin-bottom: 12px;
  }
}
