@import "scss/_variables.scss";

.work-table-admin {
  th.work-table-admin__month-th {
    text-align: center;
    background-color: $bgSuccess;
  }
  &.table > tbody > tr > td {
    border-bottom: 2px solid black;
  }
  td.work-table-admin__orderMan {
    vertical-align: middle;
    min-width: 100px;
  }
  td.work-table-admin__ks {
    vertical-align: middle;
    min-width: 160px;
  }
  td.work-table-admin__month {
    white-space: nowrap;
    min-width: 160px;
    padding: 3px;

    .btn {
      display: none;
    }

    &:hover {
      .btn {
        display: inline-block;
      }
    }
  }
}