@import "scss/_variables.scss";
@import "scss/_mixins.scss";

.pagination {
  margin-bottom: 20px;
  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &__list-item {}
  &__list-item-btn {
    @include reset-button-styles();
    &:hover {
      text-decoration: none;
    }
  }
  &__list-item-btn-text {
    padding: 6px 12px;
    color: $primary;
    background-color: white;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover, &:focus {
      background-color: #eeeeee;
      text-decoration: none;
      color: $linkHoverColor;
    }

    .pagination__list-item-btn_current & {
      color: white;
      cursor: default;
      border-color: $primary;
      background-color: $primary;
      &:hover, &:focus {
        color: white;
      }
    }

    .pagination__list-item-btn[disabled] & {
      color: #777777;
      cursor: not-allowed;
      &:hover, &:focus {
        background-color: white;
        color: #777777;
      }
    }
  }
}