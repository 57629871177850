@import "scss/_mixins.scss";

.despatch-filters-list {
  &__item {
    @include reset-button-styles();
    display: block;
    width: 100%;
    text-align: left;
    padding: 2px 10px;
    color: #555555;
    @include regular();
    font-size: 14px;
    background-color: white;
    border: 1px solid #dddddd;
    border-bottom-width: 0;
    text-decoration: none;
    &:hover, &:focus {
      background-color: #f5f5f5;
    }
    &:last-child {
      border-bottom-width: 1px;
    }

    &_active {
      color: white;
      text-shadow: 0 -1px 0 #3071a9;
      border-color: #3278b3;
      background-color: #428bca;
      background-image: linear-gradient(to bottom, #428bca 0%, #3278b3 100%);
      background-repeat: repeat-x;
    }
  }
}