.auth-page {
  &__container {}
  &__form-wrap {
    padding: 20px;
    width: 360px;
    margin: 120px auto 0;
  }
  &__title {
    text-align: center;
    font-size: 24px;
  }
  &__form-footer {
    padding-top: 5px;
    text-align: center;
  }
}