@import "scss/_variables.scss";
@import "scss/_mixins.scss";


.table-despatch {
  table-layout: fixed;

  .table-despatch-th {
    text-align: center;
    background-color: #dff0d8 !important;

    span {
      display: block;
      padding: 4px 6px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.table-bordered td {
    border-bottom: 2px solid #333333;
  }

  td {
    min-width: 160px;
  }

  td.table-despatch__month {
    vertical-align: top;
    padding: 3px;
  }
}

.table-despatch-tr {
  cursor: pointer;

  &__active {
    background: $despatch-active-color;
    &:hover {
      background: darken($despatch-active-color, 20%);
    }
  }

  &__open {
    background: $despatch-open-color;
    &:hover {
      background: darken($despatch-open-color, 20%);
    }
  }

  &__car_assigned {
    background: $despatch-car-assigned-color;
    &:hover {
      background: darken($despatch-car-assigned-color, 20%);
    }
  }

  &__in_transit {
    @include despatch-object-gradient-2colors($despatch-finished-color, $despatch-in-transit-color);
    &:hover {
      background: darken($despatch-in-transit-color, 20%);
    }
  }

  &__unavailable {
    background: $despatch-unavailable-color;
    &:hover {
      background: darken($despatch-unavailable-color, 20%);
    }
  }

  &__revision {
    background: $despatch-returned-color;
    &:hover {
      background: darken($despatch-returned-color, 20%);
    }
  }

  &__return {
    background: $despatch-returned-color;
    &:hover {
      background: darken($despatch-returned-color, 20%);
    }
  }

  &__finished {
    background: $despatch-finished-color;
    border-color: darken($despatch-finished-color, 20%);
    &:hover {
      background: darken($despatch-finished-color, 20%);
    }
  }

  &__plus {

    td {
      padding: 14px 8px !important;
    }

  }
}
