.loader {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &_page {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1005;
    background-color: white;
  }
  &_modal {
    padding: 75px 0;
  }
  &__text {
    font-size: 16px;

    .loader_white & {
      color: white;
    }

    .loader_mini & {
      font-size: 14px;
    }
  }

  &__icon-wrap {
    .icon {
      font-size: 25px;
      animation: loader 1000ms linear infinite;

      .loader_mini & {
        font-size: 23px;
      }
    }
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}