// Color system

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$textColor: #333333;
$linkColor: #428bca;
$linkHoverColor: #2a6496;

//Gpa Object colors
$gpa-active-color: #e2f1ff;
$gpa-open-color: #ffe400;
$gpa-finished-color: #a3ed84;
$gpa-closed-color: #c9c9c9;
$gpa-avar-color: #f99d85;
$gpa-garant-color: #f86a7b;

$default: white;
$primary: #428bca;
$secondary: $gray-600 !default;
$success: #5cb85c;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #d9534f;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// Bg colors
$bgPrimary: #428bca;
$bgSuccess: #dff0d8;
$bgInfo: #d9edf7;
$bgWarning: #fcf8e3;
$bgDanger: #f2dede;

// Table Despatch colors
$despatch-active-color: darken(#e2f1ff, 4%);
$despatch-unavailable-color: #c9c9c9;
$despatch-plus-color: darken(#e2f1ff, 30%); // lighten(#e2f1ff, 3%); // #f1f8ff
$despatch-open-color: #ffe400;
$despatch-finished-color: #a3ed84;
$despatch-car-assigned-color: #f99d85;
$despatch-in-transit-color: #ffe400; // #f99d85;
$despatch-returned-color: #f99d85;
$despatch-canceled-color: #f86a7b;
// scss-docs-end theme-color-variables

// Prefix for :root CSS variables

$variable-prefix:             gpa- !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

//$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;
// scss-docs-end spacer-variables-maps


// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
// $font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base:            var(--#{$variable-prefix}font-sans-serif) !default;
// $font-family-code:            var(--#{$variable-prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
// $font-size-sm:                $font-size-base * .875 !default;
// $font-size-lg:                $font-size-base * 1.25 !default;

// $font-weight-lighter:         lighter !default;
// $font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
// $font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
// $line-height-sm:              1.25 !default;
// $line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
// // scss-docs-end font-variables

// // scss-docs-start font-sizes
// $font-sizes: (
//   1: $h1-font-size,
//   2: $h2-font-size,
//   3: $h3-font-size,
//   4: $h4-font-size,
//   5: $h5-font-size,
//   6: $h6-font-size
// ) !default;
// // scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
// // $headings-color:              null !default;
// scss-docs-end headings-variables

// // scss-docs-start display-headings
// $display-font-sizes: (
//   1: 5rem,
//   2: 4.5rem,
//   3: 4rem,
//   4: 3.5rem,
//   5: 3rem,
//   6: 2.5rem
// ) !default;

// $display-font-weight: 300 !default;
// $display-line-height: $headings-line-height !default;
// // scss-docs-end display-headings

// scss-docs-start type-variables
// $lead-font-size:              $font-size-base * 1.25 !default;
// $lead-font-weight:            300 !default;

// $small-font-size:             .875em !default;

// $sub-sup-font-size:           .75em !default;

// $text-muted:                  $gray-600 !default;

// $initialism-font-size:        $small-font-size !default;

// $blockquote-margin-y:         $spacer !default;
// $blockquote-font-size:        $font-size-base * 1.25 !default;
// $blockquote-footer-color:     $gray-600 !default;
// $blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y:                 $spacer !default;
// $hr-color:                    inherit !default;
// $hr-height:                   $border-width !default;
// $hr-opacity:                  .25 !default;

// $legend-margin-bottom:        .5rem !default;
// $legend-font-size:            1.5rem !default;
// $legend-font-weight:          null !default;

// $mark-padding:                .2em !default;

// $dt-font-weight:              $font-weight-bold !default;

// $nested-kbd-font-weight:      $font-weight-bold !default;

// $list-inline-padding:         .5rem !default;

// $mark-bg:                     #fcf8e3 !default;
// // scss-docs-end type-variables


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $gray-900 !default;
$body-text-align:           null !default;

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

// Links
//
// Style anchor elements.

$link-color:                              $primary !default;
$link-decoration:                         underline !default;
$link-hover-color:                        shift-color($link-color, 20%) !default;

$link-hover-decoration:                   null !default;

// $stretched-link-pseudo-element:           after !default;
// $stretched-link-z-index:                  1 !default;

// List group

// scss-docs-start list-group-variables
$list-group-color:                  $gray-900 !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           rgba($black, .125) !default;
$list-group-border-width:           1px !default;
$list-group-border-radius:          8px !default;

$list-group-item-padding-y:         $spacer * .5 !default;
$list-group-item-padding-x:         $spacer !default;
// // $list-group-item-bg-scale:          -80% !default;
// // $list-group-item-color-scale:       40% !default;

$list-group-hover-bg:               $gray-100 !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $gray-600 !default;
$list-group-disabled-bg:            $list-group-bg !default;

// $list-group-action-color:           $gray-700 !default;
// $list-group-action-hover-color:     $list-group-action-color !default;

// $list-group-action-active-color:    $body-color !default;
// $list-group-action-active-bg:       $gray-200 !default;
// scss-docs-end list-group-variables
