@import 'scss/_variables.scss';
@import 'scss/_mixins.scss';

.table-despatch-legend {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  &__item {
    margin-right: 4px;
  }
  &__item-color {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px 10px;
    height: 30px;
    color: black;
    @include bold();
    font-size: 12px;
    &_active {
      background-color: $despatch-active-color;
    }
    &_plus {
      background-color: $despatch-plus-color;
    }
    &_open {
      background-color: $despatch-open-color;
    }
    &_finished {
      background-color: $despatch-finished-color;
    }
    &_car_assigned {
      background-color: $despatch-car-assigned-color;
    }
    &_in_transit {
      @include despatch-object-gradient-2colors($despatch-finished-color, $despatch-in-transit-color);
    }
    &_returned {
      background-color: $despatch-returned-color;
    }
    &_canceled {
      background-color: $despatch-canceled-color;
    }
  }
}
