@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.transport-page {
  padding-bottom: 80px;
  &__header {
    // margin-bottom: 70px;
  }
  &__heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  &__title {
    // margin-top: 0;
    margin-bottom: 24px;
  }
  &__content {}

  .transport-nav {
    border: 1px solid $primary;

    &_wide {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 40px;
      flex-wrap: wrap;
    }
    &__link {
      display: block;
      padding: 10px 15px;
      @include regular();
      font-size: 14px;
      text-decoration: none;
      color: $primary;
      &:hover, &:focus {
        background-color: #eeeeee;
        color: #2a6496;
        text-decoration: none;
      }

      &_active {
        background-color: $primary;
        color: white;
        &:hover, &:focus {
          background-color: $primary;
          color: white;
        }
      }
    }
  }

  .transport-page__tools {
    margin-bottom: 12px;
  }
}