@import "scss/_fonts.scss";
@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.form-group {
  margin-bottom: 15px;
  &__label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 7px;
    @include bold();
    font-size: 14px;
  }
  &__error {
    margin-top: 2px;
    padding: 3px 5px;
    background-color: $bgDanger;
  }
}