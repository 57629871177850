@import 'scss/_mixins.scss';

.nav {
  &_user {
    margin-right: -15px;
  }
  &_sub-nav {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1000;
    max-height: calc(100vh - 160px);
    overflow: auto;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.15);
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    background-clip: padding-box;

    .nav_user & {
      right: 0;
      left: auto;
    }
  }

  &__scroll-wrap {
    align-items: stretch;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
  }
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .nav_sub-nav & {
      flex-direction: column;
      align-items: stretch;
      padding: 5px 0;
    }
  }
  &__list-item {
    position: relative;
    &_divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }
  }
  &__list-item-link {
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #777777;
    @include regular();
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &[type="button"] {
      background: none;
      border: 0;
      cursor: pointer;
      width: 100%;
      text-align: left;
      line-height: inherit;
      font-size: inherit;
      font-family: inherit;
    }

    &:hover, &:focus {
      color: white;
      text-decoration: none;
    }

    &_active, &[type="button"].nav__list-item-link_active {
      background-color: #080808;
      background-image: linear-gradient(to bottom, #222222 0%, #282828 100%);
      box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
      color: white;
    }

    .nav_sub-nav & {
      white-space: nowrap;
      padding: 3px 20px;
      color: #333333;
      text-shadow: none;

      &:hover {
        background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
        background-color: #e8e8e8;
        color: #262626;
      }

      &_active {
        background: none;
        box-shadow: none;
        color: #333333;
      }
    }
  }
  &__list-item-link-icon {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}