h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  line-height: 1.1;
  @include regular();
  color: inherit;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

a {
  color: $linkColor;
  text-decoration: none
}

a:hover,
a:focus {
  color: $linkHoverColor;
  text-decoration: underline
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777;
}

b,
strong {
  @include bold();
}