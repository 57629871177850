@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.table-repair-node {
  &.table > thead > tr > th {
    text-align: center;
  }
  &__docs-btn {
    @include reset-button-styles();
    color: $primary;
    font-size: 16px;
  }
}