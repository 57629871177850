@import "scss/_variables.scss";

.admin-users {
  &__title {
    margin-bottom: 20px;
  }
  &__menu {
    margin-bottom: 40px;
  }
  &__search {
    margin-bottom: 20px;
  }
  &__error-msg {
    padding: 0 0 15px;
  }
  &__footer {
    margin-bottom: 20px;
  }
}