@import 'scss/_variables.scss';

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  @include regular();
  font-size: 14px;
  background: white;

  &__warning-text {
    margin-top: 10px;
    padding: 3px 5px;
    background-color: $bgDanger;
  }
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  padding: 8px;
  vertical-align: top;
}

.table>thead>tr>th {
  text-align: left;
  vertical-align: bottom;
  border-bottom: 2px solid #a8a8a8;
}

.table-bordered {
  border: 1px solid #a8a8a8;
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td {
  border: 1px solid #a8a8a8;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
  border-bottom-width: 2px;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #f9f9f9;
}

.table-hover>tbody>tr:hover>td,
.table-hover>tbody>tr:hover>th {
  background-color: #f5f5f5
}

.table>thead>tr>td.info,
.table>tbody>tr>td.info,
.table>tfoot>tr>td.info,
.table>thead>tr>th.info,
.table>tbody>tr>th.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>tbody>tr.info>td,
.table>tfoot>tr.info>td,
.table>thead>tr.info>th,
.table>tbody>tr.info>th,
.table>tfoot>tr.info>th {
  background-color: $bgInfo;
}

.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>th,
.table>thead:first-child>tr:first-child>td {
  border-top: 0
}

.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
  padding: 5px
}