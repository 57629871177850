.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  text-decoration: none;

  &_kr {background-color: #e74c3c;}
  &_sr {background-color: #4765a0;}
  &_tr {background-color: #555;}
  &_to {background-color: #1abc9c;}
  &_tod {background-color: #9b6bcc;}
  &_nr {background-color: black;}
  &_ar {background-color: #e67e22;}
  &_gr {background-color: #9c8061;}
  &_zd {background-color: #f1c40f;}
  &_other {background-color: #9c8061;}
  &_black {background-color: black;}
  &_oop {background-color: black;}
  &_default {
    background-color: #777
  }
  &_default[href]:hover,
  &_default[href]:focus {
    background-color: #5e5e5e
  }
  &_primary {
    background-color: #428bca
  }
  &_primary[href]:hover,
  &_primary[href]:focus {
    background-color: #3071a9
  }
  &_success {
    background-color: #5cb85c
  }
  &_success[href]:hover,
  &_success[href]:focus {
    background-color: #449d44
  }
  &_info {
    background-color: #5bc0de
  }
  &_info[href]:hover,
  &_info[href]:focus {
    background-color: #31b0d5
  }
  &_warning {
    background-color: #f0ad4e
  }
  &_warning[href]:hover,
  &_warning[href]:focus {
    background-color: #ec971f
  }
  &_danger {
    background-color: #d9534f
  }
  &_danger[href]:hover,
  &_danger[href]:focus {
    background-color: #c9302c
  }
  &:empty {
    display: none;
  }
}

button.label {
  border: 0;
  cursor: pointer;
  font-family: inherit;
  line-height: normal;
}

a.label:hover,
a.label:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}