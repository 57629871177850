@import "scss/_mixins.scss";

.gpa-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  &__group {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;
    &:nth-child(even) {
      margin-right: 0;
    }
  }
  &__group-name {
    font-size: 12px;
    @include regular();
    margin-bottom: 10px;
  }
  &__group-list-wrap {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  }
}