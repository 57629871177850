@import "scss/_fonts.scss";
@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.checkbox {
  @include reset-button-styles();
  @include bold();
  font-size: 14px;
  line-height: inherit;
  color: inherit;
  &[disabled] {
    cursor: default;
  }
  &__icon {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #d8dcde;
    .checkbox[aria-checked=true] & {
      background: $primary;
    }
    .checkbox[disabled] & {
      background: #b3b9bc;
    }
  }
  &__checked {
    fill: white;
    pointer-events: none;
  }
}