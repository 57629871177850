@import "scss/_fonts.scss";
@import "scss/_mixins.scss";

.docs-node {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  &__title {
    margin-bottom: 7px;
    @include bold();
    font-size: 14px;

    .docs-node__checkbox & {
      @include regular();
    }
  }
  &__content {}
  &__checkbox {}
}