@import "scss/_fonts.scss";
@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.unit-statistics {
  margin-bottom: 15px;
  &__name {
    @include bold();
    font-size: 14px;
    margin-bottom: 7px;
  }
  &__body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__body-col {
    &_main {
      width: 50%;
      padding-right: 15px;
    }
    &_right {
      width: 50%;
      padding-left: 15px;
    }
  }
  &__error {
    margin-top: 2px;
    padding: 3px 5px;
    background-color: $bgDanger;
    margin-bottom: 15px;
  }
}