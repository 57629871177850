@import "scss/_fonts.scss";
@import "scss/_mixins.scss";

.table-mtr-list {
  &.table th {
    font-size: 12px;
    @include bold();
    text-align: center;
  }
  &.table td {
    font-size: 12px;
  }
  &.table td.table-mtr-list__td_align-center {
    text-align: center;
  }
}