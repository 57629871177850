.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  @include regular();
  line-height: 1.42857143;
  border-radius: 0;
  user-select: none;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.btn-disabled, .btn[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .65;
  box-shadow: none;
}

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:active,
.btn.active {
  background-image: none;
}
.btn-default {
  color: #333333;
  background-color: $default;
  border-color: #cccccc;
}
.btn-default:hover,
.btn-default:focus {
  color: #333333;
  background-color: #ebebeb;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active {
  color: #333333;
  background-color: #ebebeb;
  border-color: #adadad;
}
.btn-default:disabled,
.btn-default[disabled] {
  background-image: none;
}
.btn-primary {
  color: #ffffff;
  background-color: $primary;
  border-color: #357ebd;
}
.btn-primary:hover,
.btn-primary:focus {
  color: #ffffff;
  background-color: #3276b1;
  border-color: #285e8e;
}
.btn-primary:active,
.btn-primary.active {
  color: #ffffff;
  background-color: #3276b1;
  border-color: #285e8e;
}
.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-success {
  color: #ffffff;
  background-color: $success;
  border-color: #4cae4c;
}
.btn-success:hover,
.btn-success:focus {
  color: #ffffff;
  background-color: #47a447;
  border-color: #398439;
}
.btn-success:active,
.btn-success.active {
  color: #ffffff;
  background-color: #47a447;
  border-color: #398439;
}
.btn-success:disabled,
.btn-success[disabled] {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-info {
  color: #ffffff;
  background-color: $info;
  border-color: #46b8da;
}
.btn-info:hover,
.btn-info:focus {
  color: #ffffff;
  background-color: #39b3d7;
  border-color: #269abc;
}
.btn-info:active,
.btn-info.active {
  color: #ffffff;
  background-color: #39b3d7;
  border-color: #269abc;
}
.btn-info:disabled,
.btn-info[disabled] {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-warning {
  color: #ffffff;
  background-color: $warning;
  border-color: #eea236;
}
.btn-warning:hover,
.btn-warning:focus {
  color: #ffffff;
  background-color: #ed9c28;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active {
  color: #ffffff;
  background-color: #ed9c28;
  border-color: #d58512;
}
.btn-warning:disabled,
.btn-warning[disabled] {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-danger {
  color: #ffffff;
  background-color: $danger;
  border-color: #d43f3a;
}
.btn-danger:hover,
.btn-danger:focus {
  color: #ffffff;
  background-color: #d2322d;
  border-color: #ac2925;
}
.btn-danger:active,
.btn-danger.active {
  color: #ffffff;
  background-color: #d2322d;
  border-color: #ac2925;
}
.btn-danger:disabled,
.btn-danger[disabled] {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 0
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-block {
  display: block;
  width: 100%
}