.block-list {
  &__item {}
  &__item-link {
    display: block;
    padding: 10px 15px;
    &:hover, &:focus {
      text-decoration: none;
      background-color: #eeeeee;
    }
  }
}