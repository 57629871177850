.report-all {
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__col {

    .report-all__row_view-3 & {
      width: calc((100% - 30px * 2) / 3);
      margin-right: 30px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .report-all__row_view-2 & {
      width: calc((100% - 30px) / 2);
      margin-right: 30px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}