.rmm-percents {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  &__date {
    margin-right: 20px;
  }
  &__bar {
    flex: 0 1 200px;
  }
}