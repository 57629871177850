@import 'scss/_fonts.scss';
@import 'scss/_mixins.scss';

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  &_has-sub-title {
    display: block;
  }
  &__title {
    font-size: 18px;
    @include regular();
    margin-right: 5px;
    line-height: inherit;
  }
  &__sub-title {
    font-size: 14px;
    @include regular();
    margin: 10px 0;
  }
}