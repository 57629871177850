.repair-admin {
  margin-bottom: 20px;
  &__inner-wrap {
    padding: 20px 15px;
    border: 1px solid #cccccc;
  }
  &__title {}
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &__col {
    width: 50%;
  }
  &__error-msg {
    padding: 0 0 15px;
  }
}