.table-transport {
  &__orderman-tr {}
  &__orderman-td {
    background-color: #19aff0;
    color: black;
  }
  &__ks-tr {}
  &__ks-td {
    background-color: #ceffff;
    color: black;
  }
  &__repair-name-tr {}
  &__repair-name-td {
    background-color: #fcf60e;
    color: black;
  }
  &__work-item {
    font-size: 12px;
  }

  tbody > tr > td.table-dispatch__accordance {
    vertical-align: middle;
    border-left: 2px solid black;
    border-right: 2px solid black;
    text-align: center;
  }

  &__accordance {}
  &__problem-item {
    font-size: 10px;
  }
  &__problem-item-solved {
    color: #a2a2a2;
  }

  tbody > tr > td.table-dispatch__node-name {
    font-size: 10px;
    border-left: 2px solid black;
  }

  &__object-tr {
    &_hidden {
      display: none;
    }
  }
  &__node-name {}
  &__node-date1 {
    font-size: 10px;
  }
  &__node-date2 {
    font-size: 10px;
  }

  tbody > tr > td.table-dispatch__node-date3 {
    font-size: 10px;
    border-right: 2px solid black;
  }

  &__node-date3 {}

  tbody > tr > td.table-dispatch__master {
    font-size: 10px;
    vertical-align: middle;
  }

  &__master {
    &_finished {
      color: #a2a2a2;
    }
  }
  &__roster-item {
    font-size: 10px;
    &_finished {
      color: #a2a2a2;
    }
  }
  &__datestart {
    font-size: 10px;
  }
  &__dateend {
    font-size: 10px;
  }
}