// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
  display: flex;
  flex-direction: column;

  // No need to set list-style: none; since .list-group-item is block level
  padding-left: 0; // reset padding because ul and ol
  margin-bottom: 0;
  @include border-radius($list-group-border-radius);
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
  position: relative;
  display: block;
  padding: $list-group-item-padding-y $list-group-item-padding-x;
  color: $list-group-color;
  text-decoration: if($link-decoration == none, null, none);
  background-color: $list-group-bg;
  border: $list-group-border-width solid $list-group-border-color;

  &:first-child {
    @include border-top-radius(inherit);
  }

  &:last-child {
    @include border-bottom-radius(inherit);
  }

  &.disabled,
  &:disabled {
    color: $list-group-disabled-color;
    pointer-events: none;
    background-color: $list-group-disabled-bg;
  }

  // Include both here for `<a>`s and `<button>`s
  &.active {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: $list-group-active-color;
    background-color: $list-group-active-bg;
    border-color: $list-group-active-border-color;
  }

  & + & {
    border-top-width: 0;

    &.active {
      margin-top: -$list-group-border-width;
      border-top-width: $list-group-border-width;
    }
  }
}
