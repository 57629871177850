.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  @include bold();
  color: white;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #777777;
  border-radius: 10px;

  .btn-default & {
    color: #fff;
    background-color: #333
  }
  .btn-primary & {
    color: $primary;
    background-color: white;
  }
  .btn-success & {
    color: $success;
    background-color: white;
  }
  .btn-info & {
    color: $info;
    background-color: white;
  }
  .btn-warning & {
    color: $warning;
    background-color: white;
  }
  .btn-danger & {
    color: $danger;
    background-color: white;
  }
}