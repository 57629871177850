.table-admin-users {
  &__th-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      margin: 3px 0 3px auto;
    }
  }
  &__td {
    &_inactive {
      color: #a9a9a9;
    }
  }
}