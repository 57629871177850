@import 'scss/_variables.scss';
@import 'scss/_mixins.scss';

.work-table-legend {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  &__item {
    margin-right: 4px;
  }
  &__item-color {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px 10px;
    height: 30px;
    color: black;
    @include bold();
    font-size: 12px;
    &_active {
      background-color: $gpa-active-color;
    }
    &_open {
      background-color: $gpa-open-color;
    }
    &_finished {
      background-color: $gpa-finished-color;
    }
    &_avar {
      background-color: $gpa-avar-color;
    }
    &_garant {
      background-color: $gpa-garant-color;
    }
  }
}