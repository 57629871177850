.table-transport-assign {
    &__group-td {
        // padding: 16px 8px!important;
        vertical-align: top;
        background: #19aff0;
        color: black;
    }

    h5 {
        margin-bottom: 0;
    }

    .table-despatch-tr__even {
        td,
        th {
            background-color: #f9f9f9;
        }
    }
}