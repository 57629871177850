@import 'scss/_fonts.scss';
@import 'scss/_mixins.scss';
@import 'scss/_variables.scss';

.repair-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 100px;
  &__col {
    .repair-content_wide & {
      flex: 0 1 100%;
      padding-left: 0;
    }
    &_nav {
      flex: 0 1 24%;
    }
    &_content {
      flex: 0 1 76%;
      padding-left: 30px;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid $primary;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  &__title {
    font-size: 36px;
    margin-top: 0;
  }
  &__tools {
    .btn + .btn {
      margin-left: 5px;
    }
  }
}