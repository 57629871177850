@import "scss/_fonts.scss";
@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.progress {
  overflow: hidden;
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

  &__bar {
    width: 0;
    height: 100%;
    font-size: 12px;
    @include regular();
    line-height: 20px;
    color: white;
    text-align: center;
    background-color: $primary;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width .6s ease;

    &_success {
      background-color: $success;
    }
    &_info {
      background-color: $info;
    }
    &_warning {
      background-color: $warning;
    }
    &_danger {
      background-color: $danger;
    }
    &[data-progress="0"] {
      color: #777;
      min-width: 30%;
      background-color: transparent;
      background-image: none;
      box-shadow: none;
    }
  }
  &__btn {
    @include reset-button-styles();
    @include regular();
    font-size: inherit;
    color: white;
    text-decoration: none;
    &_color-gray {
      color: #333333;
    }

    &:hover, &:focus {
      color: $linkHoverColor;
      text-decoration: underline;
    }

    .progress__bar[data-progress="0"] & {
      color: inherit;
    }
  }
}