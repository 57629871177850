@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.table-rmm {
  &.table > thead > tr > th {
    text-align: center;
  }
  &__priority-td {
    .table-bordered & {
      border-left-width: 5px;
    }

    &_primary {
      .table-bordered & {
        border-left-color: $primary;
      }
    }
    &_warning {
      .table-bordered & {
        border-left-color: $warning;
      }
    }
    &_danger {
      .table-bordered & {
        border-left-color: $danger;
      }
    }
  }
  &__last-td {
    text-align: center;
    .btn {
      margin-bottom: 4px;
    }
  }
  &__docs-btn {
    @include reset-button-styles();
    color: $primary;
    font-size: 16px;
  }
}