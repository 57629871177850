.table-tscontrol {
  .table-tscontrol-th {
    text-align: center;
    vertical-align: top;
  }
}

.table-tscontrol-transport-td {
  background-color: #fcf60e;
  span{
    color: #000;
  }
}

.table-tscontrol-tr {
  &__open,
  &__revision,
  &__car_assigned {
    opacity: .3;
  }

  &__plus {
    
  }
}
