@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.no-nar-list {
  margin-bottom: 10px;
  @include regular();
  font-size: 14px;
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #333333;
    padding-bottom: 8px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item-link {
    color: $linkColor;
    text-decoration: none;
    &:hover, &:focus {
      color: $linkHoverColor;
      text-decoration: underline;
    }
  }
}