@import "scss/_fonts.scss";
@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.repair-nav {
  border: 1px solid $primary;
  &_wide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 100px;
    flex-wrap: wrap;
  }
  &__link {
    display: block;
    padding: 10px 15px;
    @include regular();
    font-size: 14px;
    text-decoration: none;
    color: $primary;
    &:hover, &:focus {
      background-color: #eeeeee;
      color: #2a6496;
      text-decoration: none;
    }

    &_active {
      background-color: $primary;
      color: white;
      &:hover, &:focus {
        background-color: $primary;
        color: white;
      }
    }
  }
}