@import 'scss/_mixins.scss';

.tabs {
  &__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__menu-item {
    flex: 1 0 auto;
  }
  &__menu-item-btn {
    @include reset-button-styles();
    border: 1px solid transparent;
    width: 100%;
    text-align: center;
    padding: 10px 15px;
    border-bottom: 1px solid #dddddd;
    &:hover, &:focus {
      border-color: #eeeeee #eeeeee #dddddd;
      background: #eeeeee;
    }

    .tabs__menu-item_active & {
      border: 1px solid #dddddd;
      border-bottom-color: white;
      background-color: white;
      cursor: default;
    }
  }
  &__menu-item-btn-name {
    color: #428bca;
    @include regular();
    font-size: 14px;

    .tabs__menu-item_active & {
      color: #555;
    }
  }
  &__content-wrap {}
  &__content {}
  &__content-inner {
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid #dddddd;
    border-top: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  &__content-scroll-wrap {
    padding: 15px;
    height: 210px;
    overflow-y: scroll;
  }
  &__btn-wrap {
    text-align: center;
    margin-bottom: 20px;
  }
}