.mtr-page {
  padding-bottom: 50px;
  &__title {
    margin-bottom: 50px;
  }
  &__upload-file {}
  &__input-wrap {
    margin-bottom: 20px;
  }
  &__btn-wrap {
    margin-bottom: 50px;
  }
  &__content {
    margin-bottom: 50px;
  }
}