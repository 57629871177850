@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.despatch-page {
  &__menu {
    margin-bottom: 20px;
  }

  .despatch-page__header {
  }

  .despatch-content__header {
    margin-bottom: 24px;
  }

  .despatch-page__title {
    margin-bottom: 24px;
  }

  .despatch-page__content {
    margin-bottom: 80px;
  }

  .despatch-page__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .despatch-page__col {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% / 2);
  }

  .despatch-page__tools {
    margin-bottom: 16px;
  }

  .despatch-details {

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 3px solid $primary;
    }

    &__label {
      font-size: 18px;
      @include bold();
    }

    &__text {
      font-size: 18px;
      @include regular();
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #e5e5e5;
    }


    &__col {
      width: calc(100% / 2.5);
    }
    &__noty {
      padding: 3px 5px;
    }
  }

  .despatch-details__title {
    font-size: 22px;
    @include bold();
    margin-bottom: 24px;
  }

  .despatch-details__content {
    padding-bottom: 40px;
  }

  .despatch-content__tools {
    .btn + .btn {
      margin-left: 12px;
    }
  }

  .despatch-comments__cta {
    margin-bottom: 12px;
  }
}

