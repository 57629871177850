.work-table {
  th.work-table__month-th {
    text-align: center;
    background: #dff0d8;
  }

  &.table-bordered td {
    border-bottom: 2px solid #333333;
  }

  td {
    min-width: 160px;
  }
  td.work-table__orderman {
    vertical-align: middle;
  }
  td.work-table__month {
    vertical-align: top;
    padding: 3px;
  }
  td.work-table__ks {
    vertical-align: middle;
  }
}