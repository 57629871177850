@import "scss/_mixins.scss";

// Visibility utilities

.hidden {
    display: none !important;
}

.mr-1 {
	margin-right: .5rem;
}

.text-overflow {
    @include text-overflow();
}

.link-like {
    color: #428bca;
    text-decoration: none;
    cursor: pointer;

    &:hover, 
    &:focus {
        color: #2a6496;
        text-decoration: underline;
    }

    .link-like-disabled,
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      opacity: .65;
    }
}

// Display
// -------------------------

// disabled
// .u-d-table {
//     display: table !important;
// }

// disabled
// .u-d-table-cell {
//     display: table-cell !important;
// }

// disabled
// .u-table-fixed {
//     table-layout: fixed !important;
// }

.u-d-block {
    display: block !important;
}

// disabled
// .u-d-inline {
//     display: inline !important;
// }

.u-d-inline-block {
    display: inline-block !important;
}

.u-d-flex {
    display: -webkit-box !important;
    display: flex !important;
}

.u-justify-content-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}

// Colors
// -------------------------

.c-white {
    color: white;
}