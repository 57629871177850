@import 'scss/_variables.scss';

.modal-error {
  padding: 15px;
  &_border {
    border-top: 1px solid #e5e5e5;
  }
  &__msg {
    padding: 3px 5px;
    background-color: $bgDanger;
  }
}
