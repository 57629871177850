.admin-page {
  padding-bottom: 50px;
  &__container {}
  &__content-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__col {
    &_years {
      width: 45%;
      padding-right: 76px;
    }
    &_orderMan {
      width: 55%;
      padding-left: 76px;
    }
  }
}