.rules-page {
  &__head {
    margin-bottom: 20px;
  }
  &__search {
    margin-bottom: 20px;
  }
  &__search-inner {
    width: 33%;
  }
  &__content {}
  &__doc-row {}
  &__doc-row-title {
    margin-bottom: 10px;
  }
  &__doc {
    padding: 15px 25px;
    margin: 0 0 20px;
    font-size: 17px;
    border-left: 5px solid #eeeeee;
  }
  &__doc-wrap {}
}