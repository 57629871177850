@import "scss/_fonts.scss";
@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.employment-table {
  th {
    text-align: left;
  }
  &.table-hover > tbody > tr.employment-table__department > td {
    background: #5d5d5c;
    color: white;

    &:hover {
      background: #5d5d5c;
      color: white;
    }
  }
  &__btn {
    @include reset-button-styles();

    @include regular();
    font-size: inherit;
    color: $linkColor;
    text-align: left;

    .icon {
      font-size: 16px;
    }

    &:hover {
      color: $linkHoverColor;
      text-decoration: underline;
    }
  }
}