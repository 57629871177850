@import "scss/_mixins.scss";
@import "scss/_variables.scss";

.table-mtr-list {
  &__head {
    border: 1px solid #a8a8a8;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
  &__th, &__td {
    padding: 8px;
    border-right: 1px solid #a8a8a8;
    &_info {
      width: 77%;
    }
    &_select {
      width: 23%;
    }

    &:last-child {
      border-right: 0;
    }
  }
  &__body {
    border: 1px solid #a8a8a8;
  }
  &__tr {
    border-bottom: 1px solid #a8a8a8;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    &:last-child {
      border-bottom: 0;
    }
  }
  &__warning {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 3px 5px;
  }
}