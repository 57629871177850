@import "scss/_variables.scss";

.block {
  padding: 20px;
  border: 1px solid #cccccc;
  &__label {
    padding: 10px;
    background-color: $bgDanger;
  }
  &__footer {
    margin-top: 80px;
  }
}